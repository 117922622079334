<template>
  <div class="mbti-container">
    <!-- 完成统计部分 -->
    <div class="statistics-section">
      <h3>MBTI测评完成情况</h3>
      <div class="statistics-content">
        <el-card>
          <div class="stat-item">
            <span class="label">已完成人数：</span>
            <span class="value">{{ completedCount }}</span>
          </div>
        </el-card>
      </div>
    </div>

    <!-- 数据表格部分 -->
    <div class="table-section">
      <h3>MBTI测评结果列表</h3>
      <el-table
        :data="assessmentList"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        :header-cell-style="{background: '#f5f7fa', color: '#606266'}"
      >
        <el-table-column prop="name" label="姓名" min-width="120" align="center" />
        <el-table-column prop="gender" label="性别" min-width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.gender }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" min-width="130" align="center" />
        <el-table-column prop="className" label="班级" min-width="150" align="center" />
        <el-table-column prop="completedAt" label="测评时间" min-width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.completedAt }}
          </template>
        </el-table-column>
        <el-table-column prop="result" label="测评结果" min-width="120" align="center" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { getSchoolMbtiResults } from '@/request/api'

export default {
  name: 'MbtiAssessment',
  data() {
    return {
      completedCount: 0,
      assessmentList: [],
      loading: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.loading) return
      
      this.loading = true      
      try {
        const res = await getSchoolMbtiResults()
        this.loading = false

        if (res.data.code === "1000") {
          this.completedCount = res.data.data.length
          this.assessmentList = res.data.data.map(item => {
            console.log('Processing item:', item)
            return {
              name: item.name || '',
              gender: item.gender || '',
              phone: item.cell_phone || '',
              className: item.college || '',
              completedAt: this.formatDate(item.created || new Date()),
              result: item.mbti_result || ''
            }
          })
        } else {
          this.$message.error(res.data.msg || '获取数据失败')
        }
      } catch (error) {
        console.error('获取数据失败:', error)
        this.$message.error('获取数据失败')
      } finally {
        this.loading = false
      }
    },
    formatDate(timestamp) {
      const date = new Date(timestamp)
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    }
  }
}
</script>

<style scoped>
.mbti-container {
  padding: 20px;
}

.statistics-section {
  margin-bottom: 30px;
}

.statistics-content {
  margin-top: 15px;
}

.stat-item {
  font-size: 16px;
  padding: 10px;
}

.label {
  color: #666;
}

.value {
  font-weight: bold;
  color: #409EFF;
}

.table-section {
}
</style>
