<template>
  <div class="firm-recommend">
    <div class="recommend-main">
      <div class="main-accurate">
        <el-row class="main-top" type="flex" justify="space-between">
          <el-col :span="6">
            <el-image
              class="accurate-img"
              :src="require(`@/assets/image/accurate.png`)"
              fit="contain"
            ></el-image>
          </el-col>
          <!-- <el-col class="jobSelect" :span="6">
            <el-select
              v-model="resultMBTI"
              filterable
              placeholder="MBTI职业测试"
              @change="handleSelectChange"
            >
              <el-option
                v-for="item in MBTIOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-col> -->
          <!-- <el-col class="jobSelect" :span="6">
            <el-select
              v-model="resultHLD"
              filterable
              placeholder="霍兰德职业测试"
              @change="handleSelectChange"
            >
              <el-option
                v-for="item in HLDOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-col> -->
          <el-col class="jobSelect" :span="6">
            <el-select
              v-model="shop_user_job_id"
              filterable
              placeholder="招聘职位"
              @change="change"
            >
              <el-option
                v-for="item in ChainJobList"
                :key="item.shop_user_job_id"
                :label="item.job_title"
                :value="item.shop_user_job_id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <div class="accurate-data">
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="12"
              v-for="item in jobList"
              :key="item.shop_user_job_id"
            >
              <div class="col-data">
                <div class="data-top">
                  <div class="top-left">
                    <div class="top-left-left">
                      <el-image
                        class="img"
                        :src="item.img"
                        fit="contain"
                      ></el-image>

                      <div class="salary">
                        <div class="salaryword">{{ item.job_salary }}</div>
                      </div>
                    </div>
                    <div class="top-mid-left">
                      <div class="name">{{ item.name }}</div>
                      <div class="subhead">
                        {{ item.age }}岁 | {{ item.job_age }}年 |
                        {{ item.education }}
                      </div>
                      <div class="mbtititle">
                        MBTI：
                        <div class="mbti">{{ item.MBTI }}</div>
                      </div>
                      <div class="Hollandtitle">
                        霍兰德职业兴趣：
                        <div class="Holland">{{ item.HLD }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div
                      class="right-line"
                      v-for="item1 in item.education_data"
                      :key="item1.id"
                    >
                      <div class="top-right-time">
                        <div class="time">
                          {{ item1.start_time }} - {{ item1.end_time }}
                        </div>
                      </div>
                      <div class="top-right-line">
                        <div class="exp">
                          <div class="position">{{ item1.college }}</div>
                          <div class="point">·</div>
                          <div class="work">
                            {{ item1.specialty }} · {{ item1.education }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="data-bottom">
                  <div class="bottom-left">
                    <div class="aianalysis">AI分析：{{ item.HLD_DATA }}</div>
                  </div>
                  <div class="bottom-right">
                    <div class="communication">
                      <div class="comword" @click="sendChat(item)">
                        立即沟通
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="accurate-button" @click="change">
          <div class="button">
            <i class="el-icon-refresh"></i>
            <span>换一换</span>
          </div>
        </div>
      </div>
      <div class="main-dim">
        <el-image
          class="dim-img"
          :src="require(`@/assets/image/dim.png`)"
          fit="contain"
        ></el-image>
        <div class="dim-data">
          <el-row :gutter="20">
            <el-col
              :span="24"
              v-for="item in indistinctList"
              :key="item.shop_user_id"
            >
              <div class="col-data">
                <el-row class="data-top">
                  <el-col :span="14" class="top-left">
                    <el-row>
                      <el-col :span="4" class="top-left-left">
                        <el-image
                          class="img"
                          :src="item.img"
                          fit="contain"
                        ></el-image>
                        <div class="salary">
                          <div class="salaryword">{{ item.job_salary }}</div>
                        </div>
                      </el-col>
                      <el-col :span="7" class="top-mid-left">
                        <div class="name">{{ item.name }}</div>
                        <div class="subhead">
                          {{ item.age }} | {{ item.job_age }} |
                          {{ item.education }}
                        </div>

                        <div class="mbtititle">
                          MBTI：
                          <div class="mbti">{{ item.MBTI }}</div>
                        </div>
                        <div class="Hollandtitle">
                          霍兰德职业兴趣：
                          <div class="Holland">{{ item.HLD }}</div>
                        </div>
                      </el-col>
                      <el-col :span="13" class="top-mid">
                        <div class="aianalysis">
                          AI分析：{{ item.HLD_DATA }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="7" class="right">
                    <div
                      class="right-line"
                      v-for="item1 in item.education_data"
                      :key="item1.id"
                    >
                      <div class="top-right-time">
                        <div class="time">
                          {{ item1.start_time }} - {{ item1.end_time }}
                        </div>
                      </div>
                      <div class="top-right-line">
                        <div class="exp">
                          <div class="position">{{ item1.college }}</div>
                          <div class="point">·</div>
                          <div class="work">
                            {{ item1.specialty }} · {{ item1.education }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="3" class="communication">
                    <div class="comword" @click="sendChat(item)">立即沟通</div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <MyPagination :total="total" :pageSize="query_info.limit" @changePage="changePage" :currentPage="query_info.page" />
  </div>
</template>

<script>
import {
  firmInsertCommunicate,
  getChainJob,
  preciseRecommend,
  indistinctRecommend,
} from "@/request/api";
import MyPagination from '../../../../../components/MyPagination.vue'

export default {
  components: {
    MyPagination
  },
  data() {
    return {
      total: 0,
      query_info: {
        limit: 10,
        page: 1
      },
      jobList: [],
      userObj: [],
      indistinctList: [],
      ChainJobList: [],
      shop_user_job_id: "",
      resultHLD: "",
      HLDOptions: [
        "【现实型】",
        "【艺术型】",
        "【社会型】",
        "【企业型】",
        "【研究型】",
        "【常规型】",
      ],
      resultMBTI: "",
      MBTIOptions: [
        "INFP<哲学家型>",
        "INFJ<博爱型>",
        "ENFP<公关型>",
        "ENFJ<教导型>",
        "INTP<学者型>",
        "INTJ<专家型>",
        "ENTP<智多星型>",
        "ENTJ<统帅/CEO型>",
        "ISFJ<照顾者型>",
        "ISTJ<检查员型>",
        "ESFJ<主人型>",
        "ESTJ<管家型>",
        "ISFP<艺术家型>",
        "ISTP<冒险家型>",
        "ESFP<表演者型>",
        "ESTP<挑战者型>",
      ],
    };
  },
  methods: {
    sendExit() {
      this.$emit("update-value");
    },
    async sendChat(item) {
      let postData = {
        shop_user_job_id: this.shop_user_job_id,
        shop_user_id: item.shop_user_id,
      };
      const res = await firmInsertCommunicate(postData);
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      const emitData = { option_id: 3 };
      this.$router.push("/workFirm/FirmChat");
      this.$emit("cutTags", emitData);
    },
    async handleSelectChange() {
      console.log('未完待续……')
    },
    async change() {
      const filteredData = this.userObj.filter(item => item.shop_user_job_id === this.shop_user_job_id)

      // 对筛选后的数组进行随机排序
      const shuffledData = filteredData.sort(() => 0.5 - Math.random());
 
      // 取出前两个对象
      this.jobList = shuffledData.slice(0, 2);
    },
    async init() {
      try {
        const res = await getChainJob();
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
        this.ChainJobList = res.data.data.filter((ele) => ele.is_enable === 1);
        this.shop_user_job_id = this.ChainJobList[0].shop_user_job_id;
        const chainJobList = res.data.data.map(ele => ele.shop_user_job_id)

        const jobListMain = localStorage.getItem('jobListMain')
        if (jobListMain) {
          let userObj = JSON.parse(jobListMain)
          let currentTime = new Date().getTime()
          if (currentTime > userObj.expirationTime) {
            // 数据已过期
            console.log('数据已过期')
            this.getRecommend(chainJobList.join())
          } else {
            // 数据未过期
            console.log('数据未过期')

            this.userObj = Object.values(userObj)
            this.change()
          }
        } else {
          this.getRecommend(chainJobList.join())
        }
        //this.getCourse(this.stageGradeList[0].chat_stage_grade_id)
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }

    },
    async getRecommend(chainJobList) {
      try {
        const res = await preciseRecommend(chainJobList)
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
        const jobList = res.data.data

        let currentTime = new Date().getTime();
        let expirationTime = currentTime + 5 * 3600 * 1000;
        localStorage.setItem('jobListMain', JSON.stringify({ ...jobList, expirationTime }))
      } catch(error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    async indistinctRecommendApi() {
      try {
        const res = await indistinctRecommend(this.query_info.limit, this.query_info.page);
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
        this.indistinctList = res.data.data.questionnaireitem;
        this.total = res.data.data.totalNumber
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    // 自定义事件 取到分页的页码
    changePage (page) {
      this.query_info.page = page
      this.indistinctList = []
      this.indistinctRecommendApi()
    }
  },
  mounted() {
    this.init();
    this.indistinctRecommendApi();
  },
};
</script>

<style lang="less" scoped>
.firm-recommend {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .recommend-main {
    height: 92%;
    overflow-y: scroll;
    overflow-x: hidden;
    .main-dim {
      height: calc(100% - 330px);
      .dim-img {
        margin-top: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
      .dim-data {
        width: 100%;
        .el-row {
          height: 100%;
          .el-col {
            height: 100%;
            padding: 1%;
            .col-data {
              background-color: #fff;
              height: 100%;
              border-radius: 4px;
              box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
              display: flex;
              flex-direction: column;
              .data-top {
                height: 100%;
                padding: 12px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                .top-left {
                  // display: flex;
                  // flex-direction: row;
                  // justify-content: space-between;
                  .top-left-left {
                    // display: flex;
                    // flex-direction: column;
                    .img {
                      border-radius: 50%;
                      background-color: #d7dee6;
                      height: 80px;
                      width: 84px;
                    }
                    .salary {
                      margin-top: 10px;
                      height: 23px;
                      width: 84px;
                      border-radius: 20px;
                      background-color: #f4a933;
                      .salaryword {
                        margin-top: 1px;
                        margin-left: 10px;
                        font-size: 17px;
                        font-weight: 800;
                        color: #ffffff;
                      }
                    }
                  }
                  .top-mid-left {
                    display: flex;
                    flex-direction: column;
                    // margin-left: 10px;
                    .name {
                      margin-top: 2.5px;
                      font-size: 19px;
                      font-weight: 800;
                      color: #000000;
                    }
                    .subhead {
                      margin-top: 13px;
                      font-size: 14px;
                      color: #626262;
                    }
                    .mbtititle {
                      margin-top: 9px;
                      font-size: 14px;
                      color: #626262;
                      display: flex;
                      flex-direction: row;
                      .mbti {
                        font-size: 14px;
                        font-weight: 700;
                        color: #000000;
                        display: flex;
                      }
                    }
                    .Hollandtitle {
                      margin-top: 9px;
                      font-size: 14px;
                      color: #626262;
                      display: flex;
                      flex-direction: row;
                      .Holland {
                        font-size: 14px;
                        font-weight: 700;
                        color: #000000;
                      }
                    }
                  }
                  .top-mid {
                    // margin-top: 2.5px;
                    display: flex;
                    // margin-left: 5px;
                    font-size: 14px;
                    .aianalysis {
                      color: #2b6fe9;
                    }
                  }
                }
                .right {
                  display: flex;
                  flex-direction: column;
                  .right-line {
                    display: flex;
                    flex-direction: row;
                    .top-right-time {
                      margin-right: 10px;
                      .time {
                        font-size: 14px;
                        color: #8b8b8b;
                        margin-top: 9px;
                      }
                    }
                    .top-right-line {
                      display: flex;
                      .exp {
                        display: flex;
                        flex-direction: row;
                        margin-top: 9px;
                        .position {
                          font-size: 14px;
                          color: #000000;
                        }
                        .point {
                          margin-left: 3px;
                          margin-right: 3px;
                          font-size: 14px;
                          font-weight: bold;
                          color: rgb(135, 133, 133);
                        }
                        .work {
                          font-size: 14px;
                          color: #000000;
                        }
                      }
                    }
                  }
                }
                .communication {
                  margin: 0 auto;
                  .comword {
                    border-radius: 5px;
                    cursor: pointer;
                    background-color: #f4a933;
                    line-height: 35px;
                    // margin-top: 1px;
                    // margin-left: 16px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                    text-align: center;
                    &:hover {
                      background-color: #f5b95a;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .main-accurate {
      height: 300px;
      .main-top {
        width: 100%;
        // display: flex;
        // flex-direction: row;
        .accurate-img {
          height: 30px;
          margin-bottom: 10px;
        }
        .content {
          padding-left: 65%;
          height: 5%;
          font-size: 15px;
          display: flex;
          align-items: center;
          color: #000;
          .form-select {
            padding-left: 1%;
          }
        }
      }
      .jobSelect {
        text-align: right;
      }
      .accurate-data {
        height: 300px;
        .el-row {
          height: 100%;
          .el-col {
            height: 100%;
            padding: 1%;
            .col-data {
              background-color: #fff;
              height: 100%;
              border-radius: 4px;
              box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
              display: flex;
              flex-direction: column;
              .data-top {
                height: 50%;
                padding: 12px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                .top-left {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  .top-left-left {
                    display: flex;
                    flex-direction: column;
                    .img {
                      border-radius: 50%;
                      background-color: #d7dee6;
                      height: 80px;
                      width: 84px;
                    }
                    .salary {
                      margin-top: 10px;
                      height: 23px;
                      width: 84px;
                      border-radius: 20px;
                      background-color: #f4a933;
                      .salaryword {
                        margin-top: 1px;
                        margin-left: 10px;
                        font-size: 17px;
                        font-weight: 800;
                        color: #ffffff;
                      }
                    }
                  }
                  .top-mid-left {
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    .name {
                      font-size: 19px;
                      font-weight: 800;
                      color: #000000;
                    }
                    .subhead {
                      margin-top: 13px;
                      font-size: 14px;
                      color: #626262;
                    }
                    .mbtititle {
                      margin-top: 9px;
                      font-size: 14px;
                      color: #626262;
                      display: flex;
                      flex-direction: row;
                      .mbti {
                        font-size: 14px;
                        font-weight: 700;
                        color: #000000;
                        display: flex;
                      }
                    }
                    .Hollandtitle {
                      margin-top: 9px;
                      font-size: 14px;
                      color: #626262;
                      display: flex;
                      flex-direction: row;
                      .Holland {
                        font-size: 14px;
                        font-weight: 700;
                        color: #000000;
                      }
                    }
                  }
                }
                .right {
                  display: flex;
                  flex-direction: column;
                  .right-line {
                    display: flex;
                    flex-direction: row;
                    .top-right-time {
                      margin-right: 10px;
                      .time {
                        font-size: 14px;
                        color: #8b8b8b;
                        margin-top: 9px;
                      }
                    }
                    .top-right-line {
                      display: flex;
                      .exp {
                        display: flex;
                        flex-direction: row;
                        margin-top: 9px;
                        .position {
                          font-size: 14px;
                          color: #000000;
                        }
                        .point {
                          margin-left: 3px;
                          margin-right: 3px;
                          font-size: 14px;
                          font-weight: bold;
                          color: rgb(135, 133, 133);
                        }
                        .work {
                          font-size: 14px;
                          color: #000000;
                        }
                      }
                    }
                  }
                }
              }
              .data-bottom {
                height: 58%;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px;
                font-size: 14px;
                .bottom-left {
                  display: flex;
                  width: 77%;
                  align-items: center;
                  .aianalysis {
                    color: #2b6fe9;
                  }
                }
                .bottom-right {
                  display: flex;
                  .communication {
                    width: 80px;
                    height: 80px;
                    margin-right: 40px;
                    border-radius: 5px;
                    cursor: pointer;
                    background-color: #f4a933;
                    &:hover {
                      background-color: #f5b95a;
                    }
                    .comword {
                      margin-top: 10px;
                      margin-left: 18.3px;
                      font-size: 21.8px;
                      font-weight: bold;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .accurate-button {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1%;
        .button {
          font-size: 14px;
          color: #3e83ff;
          cursor: pointer;
          border-bottom: 1px solid #2a6ee9;
        }
      }
    }
  }
  .recommend-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-right {
      width: 300px;
      height: 100%;
      padding-right: 1%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right-title {
        width: 60%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #2a6ee9;
        font-weight: bold;
        .title {
          border-bottom: 1px solid #2a6ee9;
          cursor: pointer;
        }
      }
      .exit {
        width: 70px;
        cursor: pointer;
      }
    }
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
  }
}
</style>
