<template>
  <div class="teaching-assistant-container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'TeachingAssistant',
  data() {
    return {}
  }
}
</script>

<style scoped>
.teaching-assistant-container {
  padding: 20px;
}
</style>
